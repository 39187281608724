/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    // START / Widgets
    // START / Widgets / Account
    {
        name: 'AccountInfo',
        import: () => import('@stories/Widgets/Account/AccountInfo/AccountInfo'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AccountNavigation',
        import: () => import('@stories/Widgets/Account/AccountNavigation/AccountNavigation'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AddressInfo',
        import: () => import('@stories/Widgets/Account/AddressInfo/AddressInfo'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'OrderDetail',
        import: () => import('@stories/Widgets/Account/OrderDetail/OrderDetail'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'OrderList',
        import: () => import('@stories/Widgets/Account/OrderList/OrderList'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'OrderUpload',
        import: () => import('@stories/Widgets/Account/OrderUpload/OrderUpload'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'LogInRegistration',
        import: () => import('@stories/Widgets/Account/LogInRegistration/LogInRegistration'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    // END / Widgets / Account
    // START / Widgets / AuthorBlock
    {
        name: 'AuthorBlock',
        import: () => import('@stories/Widgets/AuthorBlock/AuthorBlock'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    // END / Widgets / AuthorBlock
    // START / Widgets / BrandBackground
    {
        name: 'BrandBackground',
        import: () => import('@stories/Widgets/BrandBackground/BrandBackground'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    // END / Widgets / BrandBackground
    // START / Widgets / Breadcrumb
    {
        name: 'Breadcrumb',
        import: () => import('@stories/Widgets/Breadcrumb/Breadcrumb'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    // END / Widgets / Breadcrumb
    // START / Widgets / Calculator
    {
        name: 'Calculator',
        import: () => import('@stories/Widgets/Calculator/Calculator'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    // END / Widgets / Calculator
    // START / Widgets / Checkout
    {
        name: 'Basket',
        import: () => import('@stories/Widgets/Checkout/Basket/Basket'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'Confirmation',
        import: () => import('@stories/Widgets/Checkout/Confirmation/Confirmation'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'Review',
        import: () => import('@stories/Widgets/Checkout/Review/Review'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'Shipping',
        import: () => import('@stories/Widgets/Checkout/Shipping/Shipping'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    // END / Widgets / Checkout
    // START / Widgets / ComparisonOverlay
    {
        name: 'ComparisonOverlay',
        import: () => import('@stories/Widgets/ComparisonOverlay/ComparisonOverlay'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    // END / Widgets / ComparisonOverlay
    // START / Widgets / Content
    {
        name: 'AddressCards',
        import: () => import('@stories/Widgets/Content/AddressCards/AddressCards'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'AgnosticCalculator',
        import: () => import('@stories/Widgets/AgnosticCalculator/AgnosticCalculator'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'Alert',
        import: () => import('@stories/Widgets/Content/Alert/Alert'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'BlockQuote',
        import: () => import('@stories/Widgets/Content/BlockQuote/BlockQuote'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'CategoryCardList',
        import: () => import('@stories/Widgets/Content/CategoryCardList/CategoryCardList'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ContentCardList',
        import: () => import('@stories/Widgets/Content/ContentCardList/ContentCardList'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'FAQs',
        import: () => import('@stories/Widgets/Content/FAQs/FAQs'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'FeaturedProductResource',
        import: () => import('@stories/Widgets/Content/FeaturedProductResource/FeaturedProductResource'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'FeaturedRoadshow',
        import: () => import('@stories/Widgets/Content/FeaturedRoadshow/FeaturedRoadshow'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'FiftyFiftySignpost',
        import: () => import('@stories/Widgets/Content/FiftyFiftySignpost/FiftyFiftySignpost'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'Image',
        import: () => import('@stories/Widgets/Content/Image/Image'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ImageCarousel',
        import: () => import('@stories/Widgets/Content/ImageCarousel/ImageCarousel'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'InformationCard',
        import: () => import('@stories/Widgets/Content/InformationCard/InformationCard'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductBrochure',
        import: () => import('@stories/Widgets/Content/ProductBrochure/ProductBrochure'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductCardList',
        import: () => import('@stories/Widgets/Content/ProductCardList/ProductCardList'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductDatasheets',
        import: () => import('@stories/Widgets/Content/ProductDatasheets/ProductDatasheets'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ResourceCardList',
        import: () => import('@stories/Widgets/Content/ResourceCardList/ResourceCardList'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RichText',
        import: () => import('@stories/Widgets/Content/RichText/RichText'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ShortHeroBanner',
        import: () => import('@stories/Widgets/Content/ShortHeroBanner/ShortHeroBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'VideoSlider',
        import: () => import('@stories/Widgets/Content/VideoSlider/VideoSlider'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ViewportHeroBanner',
        import: () => import('@stories/Widgets/Content/ViewportHeroBanner/ViewportHeroBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    // / END Widgets / Content
    // START / Widgets / Footer
    {
        name: 'Footer',
        import: () => import('@stories/Widgets/Footer/Footer'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    // END / Widgets / Footer
    // START / Widgets / Header
    {
        name: 'Header',
        import: () => import('@stories/Widgets/Header/Header'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    // END / Widgets / Header
    // START / Widgets / InPageNav
    {
        name: 'InPageNav',
        import: () => import('@stories/Widgets/InPageNav/InPageNav'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    // END / Widgets / InPageNav
    // START / Widgets / KenticoForm
    {
        name: 'KenticoForm',
        import: () => import('@stories/Widgets/KenticoForm/KenticoForm'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    // END / Widgets / KenticoForm
    // START / Widgets / Listings
    {
        name: 'ProductResourceList',
        import: () => import('@stories/Widgets/Listings/ProductResourceList/ProductResourceList'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductSearchList',
        import: () => import('@stories/Widgets/Listings/ProductSearchList/ProductSearchList'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ResourceList',
        import: () => import('@stories/Widgets/Listings/ResourceList/ResourceList'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    // END / Widgets / Listings
    // START / Widgets / Panels
    {
        name: 'PanelManager',
        import: () => import('@stories/Widgets/Panels/PanelManager/PanelManager'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    // END / Widgets / Panels
    // START / Widgets / Notifications
    {
        name: 'Notifications',
        import: () => import('@stories/Widgets/Notifications/Notifications'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    // END / Widgets / Notifications
    // START / Widgets / ProductDetail
    {
        name: 'ProductDetail',
        import: () => import('@stories/Widgets/ProductDetail/ProductDetail'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    // END / Widgets / ProductDetail
    // START / Widgets / ProductFinder
    {
        name: 'ProductFinder',
        import: () => import('@stories/Widgets/ProductFinder/ProductFinder'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    // END / Widgets / ProductFinder
    // START / Widgets / ProductReviewList
    {
        name: 'ProductReviewList',
        import: () => import('@stories/Widgets/ProductReviewList/ProductReviewList'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    // END / Widgets / ProductReviewList
    // START / Widgets / ReviewForm
    {
        name: 'ReviewForm',
        import: () => import('@stories/Widgets/ReviewForm/ReviewForm'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    // END / Widgets / ReviewForm
    // START / Widgets / SearchOverlay
    {
        name: 'SearchOverlay',
        import: () => import('@stories/Widgets/SearchOverlay/SearchOverlay'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'always',
        },
    },
    // END / Widgets / SearchOverlay
    // START / Widgets / StockistsMap
    {
        name: 'StockistsMap',
        import: () => import('@stories/Widgets/StockistsMap/StockistsMap'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    // END / Widgets / StockistsMap
    // START / Widgets / VideoDetail
    {
        name: 'VideoDetail',
        import: () => import('@stories/Widgets/VideoDetail/VideoDetail'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    // END / Widgets / VideoDetail
    // START / Widgets / FastfixCalculator
    {
        name: 'FastfixCalculator',
        import: () => import('@stories/Widgets/FastfixCalculator/FastfixCalculator'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    // END / Widgets / FastfixCalculator
    // END / Widgets
];
