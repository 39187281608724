/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom';
import { registrations } from '../registrations';
class IntersectionHydrator {
    constructor() {
        this.mapping = {};
        this.hydratedComponents = [];
        this.regsiterComponents = () => {
            // Loop through the components on the window
            for (let index = 0; index < window.ReactEnvironmentComponents.Components.length; index++) {
                const component = window.ReactEnvironmentComponents.Components[index];
                // get the entry point for the component
                const element = document.querySelector(`#${component.id}`);
                if (element === null || !this.observer) {
                    continue;
                }
                // Find the registration we have defined so we know wich file to import
                const registrationLookup = registrations.find((x) => `Components.${x.name}` === component.name);
                if (!registrationLookup) {
                    continue;
                }
                // Create a deep copy
                const registration = JSON.parse(JSON.stringify(registrationLookup));
                // Copy over the path as it's not serialisable
                registration.import = registrationLookup.import;
                // Assign the props to the registation
                registration.props = component.props;
                // Assign the registion to the mapping
                this.mapping[component.id] = registration;
                // Hydrate based on the config
                if (registration.config.hydrate) {
                    switch (registration.config.hydrateBehaviour) {
                        case 'always': {
                            this.hydrateComponent(registration, component.props, element);
                            break;
                        }
                        case 'in-view': {
                            this.observer.observe(element);
                            break;
                        }
                    }
                }
            }
        };
        this.handleIntersection = (entries) => {
            for (const entry of entries) {
                if (!entry.isIntersecting) {
                    continue;
                }
                const mapping = this.mapping[entry.target.id];
                if (!mapping) {
                    continue;
                }
                const hasHydrated = this.hydratedComponents.indexOf(entry.target.id) > -1;
                if (!hasHydrated) {
                    this.hydrateComponent(mapping, mapping.props, entry.target);
                    this.hydratedComponents.push(entry.target.id);
                }
            }
        };
        this.hydrateComponent = async (registration, props, container) => {
            registration.import().then((e) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ReactDOM.hydrate(React.createElement(e.default, props), container);
            });
        };
        if (!window.ReactEnvironmentComponents || !window.ReactEnvironmentComponents.Components) {
            return;
        }
        this.observer = new IntersectionObserver(this.handleIntersection, {
            threshold: 0,
            rootMargin: '0px',
        });
        this.regsiterComponents();
    }
}
export default new IntersectionHydrator();
